import clsx from 'clsx';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/core/styles';
import { createFragmentContainer, graphql } from 'react-relay';

import Link from './Link';

const useStyles = makeStyles(theme => ({
  '@global': {
    '#root': {
      paddingLeft: 60,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 60,
    left: 0,
    top: 0,
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: 'rgba(255, 255, 255, 0.8)',
    position: 'fixed',
    zIndex: theme.zIndex.appBar + 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxSizing: 'border-box',
  },
  avatarButton: {
    padding: theme.spacing(0.5),
  },
}));

function AppBar(props) {
  const { className, me, relay, ...other } = props;
  const s = useStyles();

  return (
    <nav className={clsx(s.root, className)} {...other}>
      <IconButton
        color="inherit"
        href={me.asanaProjectId ? `/a/${me.asanaProjectId}` : '/new'}
        component={Link}
      >
        <DashboardIcon />
      </IconButton>
      <IconButton color="inherit" href="/new" component={Link}>
        <AddIcon />
      </IconButton>
      <span style={{ flexGrow: 1 }} />
      <IconButton color="inherit">
        <NotificationsIcon />
      </IconButton>
      <IconButton className={s.avatarButton} color="inherit">
        <Avatar className={s.avatar} src={me.photoURL} alt={me.displayName} />
      </IconButton>
    </nav>
  );
}

export default createFragmentContainer(AppBar, {
  me: graphql`
    fragment AppBar_me on User {
      id
      displayName
      photoURL
      asanaProjectId
    }
  `,
});
