/**
 * @flow
 * @relayHash 9709bdf06b7b26f25de699e224e5434c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
export type landingHomeQueryVariables = {||};
export type landingHomeQueryResponse = {|
  +me: ?{|
    +id: string,
    +asanaProjectId: ?string,
  |},
  +$fragmentRefs: Layout_data$ref,
|};
export type landingHomeQuery = {|
  variables: landingHomeQueryVariables,
  response: landingHomeQueryResponse,
|};
*/


/*
query landingHomeQuery {
  ...Layout_data
  me {
    id
    asanaProjectId
  }
}

fragment Layout_data on Query {
  me {
    ...AppBar_me
    ...LayoutToolbar_me
    ...AutoUpdater_user
    id
  }
}

fragment AppBar_me on User {
  id
  displayName
  photoURL
  asanaProjectId
}

fragment LayoutToolbar_me on User {
  id
  displayName
  photoURL
}

fragment AutoUpdater_user on User {
  id
  timeZone
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "asanaProjectId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "landingHomeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "landingHomeQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photoURL",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "landingHomeQuery",
    "id": null,
    "text": "query landingHomeQuery {\n  ...Layout_data\n  me {\n    id\n    asanaProjectId\n  }\n}\n\nfragment Layout_data on Query {\n  me {\n    ...AppBar_me\n    ...LayoutToolbar_me\n    ...AutoUpdater_user\n    id\n  }\n}\n\nfragment AppBar_me on User {\n  id\n  displayName\n  photoURL\n  asanaProjectId\n}\n\nfragment LayoutToolbar_me on User {\n  id\n  displayName\n  photoURL\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2d8ec1b26d6e3a4c34e99776434ec10f';
module.exports = node;
