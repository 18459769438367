import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';

function toGlobalId(id, type = 'AsanaProject') {
  return typeof Buffer === 'undefined'
    ? btoa(`${type}:${id}`)
    : Buffer.from(`${type}:${id}`, 'utf8').toString('base64');
}

export default [
  {
    path: '/a/:id',
    query: graphql`
      query mapMapQuery($id: ID!) {
        ...Layout_data
        project(id: $id) {
          ...Map_project
          name
          workspace {
            name
          }
        }
      }
    `,
    variables: x => ({ ...x, id: toGlobalId(x.id) }),
    components: () => [import(/* webpackChunkName: 'map' */ './Map')],
    render([Map], data, { config }) {
      return data.project
        ? {
            title: `${data.project.workspace.name}: ${data.project.name} | ${config.app.name}`,
            component: (
              <Layout
                data={data}
                title={`${data.project.workspace.name}: ${data.project.name}`}
              >
                <Map project={data.project} />
              </Layout>
            ),
            chunks: ['map'],
          }
        : undefined;
    },
  },
];
