/**
 * @flow
 * @relayHash 5ecd2d2e5548ea9af552bfda764f9b6d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
type New_data$ref = any;
type New_me$ref = any;
export type newQueryVariables = {||};
export type newQueryResponse = {|
  +me: ?{|
    +id: string,
    +$fragmentRefs: New_me$ref,
  |},
  +$fragmentRefs: Layout_data$ref & New_data$ref,
|};
export type newQuery = {|
  variables: newQueryVariables,
  response: newQueryResponse,
|};
*/


/*
query newQuery {
  ...Layout_data
  ...New_data
  me {
    id
    ...New_me
  }
}

fragment Layout_data on Query {
  me {
    ...AppBar_me
    ...LayoutToolbar_me
    ...AutoUpdater_user
    id
  }
}

fragment New_data on Query {
  projects: asanaProjects {
    id
    name
  }
}

fragment New_me on User {
  id
  displayName
  asanaWorkspaces {
    id
    name
  }
}

fragment AppBar_me on User {
  id
  displayName
  photoURL
  asanaProjectId
}

fragment LayoutToolbar_me on User {
  id
  displayName
  photoURL
}

fragment AutoUpdater_user on User {
  id
  timeZone
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "newQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "New_me",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "New_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "newQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photoURL",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "asanaProjectId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "asanaWorkspaces",
            "storageKey": null,
            "args": null,
            "concreteType": "AsanaWorkspace",
            "plural": true,
            "selections": (v1/*: any*/)
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "projects",
        "name": "asanaProjects",
        "storageKey": null,
        "args": null,
        "concreteType": "AsanaProject",
        "plural": true,
        "selections": (v1/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "newQuery",
    "id": null,
    "text": "query newQuery {\n  ...Layout_data\n  ...New_data\n  me {\n    id\n    ...New_me\n  }\n}\n\nfragment Layout_data on Query {\n  me {\n    ...AppBar_me\n    ...LayoutToolbar_me\n    ...AutoUpdater_user\n    id\n  }\n}\n\nfragment New_data on Query {\n  projects: asanaProjects {\n    id\n    name\n  }\n}\n\nfragment New_me on User {\n  id\n  displayName\n  asanaWorkspaces {\n    id\n    name\n  }\n}\n\nfragment AppBar_me on User {\n  id\n  displayName\n  photoURL\n  asanaProjectId\n}\n\nfragment LayoutToolbar_me on User {\n  id\n  displayName\n  photoURL\n}\n\nfragment AutoUpdater_user on User {\n  id\n  timeZone\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b298a4bd80287d5406afc05a0ee7bfe';
module.exports = node;
